import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Android, Apple, Language } from 'grommet-icons';

export const ATTR_PROPERTY_NAMES = {
  USER_SEGMENTS: 'UserSegments',
  LOCATIONS: 'Locations',
  SOURCEID: 'QueryParams.sourceid',
  ZIP_CODE: 'profile.zip'
};

export const EXISTS = 'Exists';

export const ES_UNOMI_MAPPING = {
  Equal: 'equals',
  NotEqual: 'notEquals',
  In: 'in',
  GreaterThan: 'greaterThan',
  GreaterThanOrEqual: 'greaterThanOrEqualTo',
  LessThan: 'lessThan',
  LessThanOrEqual: 'lessThanOrEqualTo',
  Exists: 'exists'
};

export const COMPARISON_OPERATORS = [
  {
    label: '=',
    value: 'Equal'
  },
  {
    label: '!=',
    value: 'NotEqual'
  },
  {
    label: 'In',
    value: 'In'
  },
  {
    label: '>',
    value: 'GreaterThan'
  },
  {
    label: '>=',
    value: 'GreaterThanOrEqual'
  },
  {
    label: '<',
    value: 'LessThan'
  },
  {
    label: '<=',
    value: 'LessThanOrEqual'
  },
  {
    label: 'Exists',
    value: 'Exists'
  }
];

export const MEETS = [
  {
    label: 'Any',
    value: 'Any'
  },
  {
    label: 'All',
    value: 'All'
  }
];

export const CONDITION_TYPES = {
  DEVICE: 'DEVICE',
  PROFILE: 'PROFILE',
  QUERY_PARAM: 'QUERY_PARAM',
  LOCATION: 'LOCATION',
  AUDIENCE: 'AUDIENCE',
  PLACEMENT: 'PLACEMENT',
  PARTNER: 'PARTNER',
  INFERRED_ATTRIBUTES: 'INFERRED_ATTRIBUTES'
};

export const getNewCondition = (
  conditionType,
  propertyName,
  comparisonOperator,
  initialValues
) => {
  return {
    _id: uuidv4(),
    _type: conditionType,
    propertyName: propertyName || '',
    comparisonOperator: comparisonOperator || 'Equal',
    propertyValues: initialValues || [],
    inverse: false
  };
};

export const filterBooleanCondition = join => {
  return {
    join,
    subConditions: []
  };
};

export const filterCondition = (
  propertyName,
  comparisonOperator,
  propertyValues,
  inverse
) => {
  return {
    propertyName,
    comparisonOperator,
    propertyValues,
    inverse
  };
};

export const areSessionAttributesValid = sessionAttributes => {
  return sessionAttributes.every(sa => {
    if (sa.comparisonOperator === EXISTS) {
      return checkPropertyName(sa.propertyName);
    } else {
      return (
        checkPropertyName(sa.propertyName) &&
        sa.propertyValues.length > 0 &&
        !!sa.propertyValues[0]
      );
    }
  });
};

const checkPropertyName = propertyName => {
  if (!!propertyName) {
    return propertyName.split('.').every(part => !!part.trim());
  }

  return false;
};

export const buildSessionAttributeFilter = (
  sessionAttributes,
  attributesMeet
) => {
  const sessionCondition = filterBooleanCondition(attributesMeet);
  sessionCondition.subConditions = sessionAttributes.map(sa => {
    let filterPropertyName;
    if (sa._type === CONDITION_TYPES.DEVICE) {
      //uppercase the first letter of the property name because it is mapped as lowercase in elastic
      filterPropertyName = `Device.${sa.propertyName.charAt(0).toUpperCase() +
        sa.propertyName.slice(1)}`;
    } else if (sa._type === CONDITION_TYPES.PROFILE) {
      filterPropertyName = sa.propertyName;
    } else if (sa._type === CONDITION_TYPES.QUERY_PARAM) {
      filterPropertyName = sa.propertyName.substring('properties.url.'.length);
    } else if (sa._type === CONDITION_TYPES.PARTNER) {
      filterPropertyName = sa.propertyName;
    } else if (sa._type === CONDITION_TYPES.PLACEMENT) {
      filterPropertyName = `placement.${sa.propertyName}`;
    } else if (sa._type === CONDITION_TYPES.LOCATION) {
      filterPropertyName = ATTR_PROPERTY_NAMES.LOCATIONS;
    } else if (sa._type === CONDITION_TYPES.AUDIENCE) {
      filterPropertyName = ATTR_PROPERTY_NAMES.USER_SEGMENTS;
    } else if (sa._type === CONDITION_TYPES.INFERRED_ATTRIBUTES) {
      filterPropertyName = sa.propertyName;
    }

    return filterCondition(
      filterPropertyName,
      sa.comparisonOperator,
      sa.propertyValues,
      sa.inverse
    );
  });
  return sessionCondition;
};

export const getDeviceIcon = (value, size = 'medium') => {
  switch (value) {
    case 'android':
      return <Android key='android' size={size} />;
    case 'ios':
      return <Apple key='ios' size={size} />;
    case 'desktop':
      return <Language key='desktop' size={size} />;
    default:
      return null;
  }
};

export const parseDeviceTypesFromFilter = filter => {
  const { include = { subConditions: [] } } = filter || {};

  const deviceTypes = [];
  const deviceTypeBooleanCondition = include.subConditions.find(
    sc =>
      Array.isArray(sc.subConditions) &&
      sc.subConditions.find(
        subsc =>
          Array.isArray(subsc.subConditions) &&
          subsc.subConditions.find(
            subsubc => subsubc.propertyName === 'Device.OsName'
          )
      )
  );
  if (deviceTypeBooleanCondition) {
    deviceTypeBooleanCondition.subConditions.forEach(deviceGroupCondition => {
      let value, comparisonOperator, propertyValues;
      if (Array.isArray(deviceGroupCondition.subConditions)) {
        deviceGroupCondition.subConditions.forEach(c => {
          if (c.propertyName === 'Device.OsName') {
            if (
              c.comparisonOperator === 'Equal' &&
              c.propertyValues.length > 0
            ) {
              if (c.propertyValues[0] === 'Android') {
                value = 'android';
              } else if (c.propertyValues[0] === 'iOS') {
                value = 'ios';
              }
            } else if (c.comparisonOperator === 'NotEqual') {
              value = 'desktop';
            }
          } else if (c.propertyName === 'Device.OsMajorVersion') {
            comparisonOperator = c.comparisonOperator;
            propertyValues = c.propertyValues;
          }
        });
      }
      if (value) {
        deviceTypes.push({
          value,
          comparisonOperator,
          propertyValues
        });
      }
    });
  }
  return deviceTypes;
};

const formatSessionAttribute = sessionAttribute => {
  let filterPropertyName;
  let _type;

  if (sessionAttribute.propertyName.startsWith('Device.')) {
    filterPropertyName = sessionAttribute.propertyName.split('.')[1];
    filterPropertyName =
      filterPropertyName.charAt(0).toLowerCase() + filterPropertyName.slice(1);
    _type = CONDITION_TYPES.DEVICE;
  } else if (
    sessionAttribute.propertyName.startsWith('profile.partnerAttrs.')
  ) {
    filterPropertyName = sessionAttribute.propertyName;
    _type = CONDITION_TYPES.PARTNER;
  } else if (
    sessionAttribute.propertyName.startsWith('profile.inferredAttrs.')
  ) {
    filterPropertyName = sessionAttribute.propertyName;
    _type = CONDITION_TYPES.INFERRED_ATTRIBUTES;
  } else if (sessionAttribute.propertyName.startsWith('profile.')) {
    filterPropertyName = sessionAttribute.propertyName;
    _type = CONDITION_TYPES.PROFILE;
  } else if (sessionAttribute.propertyName.startsWith('queryParams.')) {
    filterPropertyName = `properties.url.${sessionAttribute.propertyName}`;
    _type = CONDITION_TYPES.QUERY_PARAM;
  } else if (sessionAttribute.propertyName.startsWith('placement.')) {
    filterPropertyName = sessionAttribute.propertyName.split('.')[1];
    _type = CONDITION_TYPES.PLACEMENT;
  } else if (sessionAttribute.propertyName === ATTR_PROPERTY_NAMES.LOCATIONS) {
    filterPropertyName = sessionAttribute.propertyName;
    _type = CONDITION_TYPES.LOCATION;
  } else if (
    sessionAttribute.propertyName === ATTR_PROPERTY_NAMES.USER_SEGMENTS
  ) {
    filterPropertyName = sessionAttribute.propertyName;
    _type = CONDITION_TYPES.AUDIENCE;
  }

  return {
    _type,
    _id: uuidv4(),
    propertyName: filterPropertyName,
    comparisonOperator: sessionAttribute.comparisonOperator,
    propertyValues: sessionAttribute.propertyValues,
    inverse: sessionAttribute.inverse
  };
};

//turns an executable filter into values that can drive the Ad Group Filter UI
export const parseFilter = (filter, ignoreSessionDevices) => {
  const { include = { subConditions: [] }, exclude = { subConditions: [] } } =
    filter || {};

  let sourceIdValues = [];
  let excludedSourceIdValues = [];
  let audienceMeet = MEETS[0].value;
  let audienceIds = [];
  let excludedAudienceMeet = MEETS[0].value;
  let excludedAudienceIds = [];
  let attributesMeet = MEETS[1].value;
  let sessionAttributes = [];
  let excludedAttributesMeet = MEETS[1].value;
  let excludedSessionAttributes = [];
  let locationIds = [];
  let excludedLocationIds = [];
  let zipCodes = [];
  let excludedZipCodes = [];

  const deviceTypes = parseDeviceTypesFromFilter(filter);

  // Build include filter

  //look for source id values
  let sourceIdCondition = include.subConditions.find(
    sc => sc.propertyName === ATTR_PROPERTY_NAMES.SOURCEID
  );
  if (sourceIdCondition) {
    sourceIdValues = sourceIdCondition.propertyValues;
  }

  //find UserSegment conditions and get audienceIds from them
  const userSegmentBooleanCondition = include.subConditions.find(
    sc =>
      Array.isArray(sc.subConditions) &&
      sc.subConditions.find(
        subsc => subsc.propertyName === ATTR_PROPERTY_NAMES.USER_SEGMENTS
      )
  );
  if (userSegmentBooleanCondition) {
    audienceMeet = userSegmentBooleanCondition.join;
    audienceIds = userSegmentBooleanCondition.subConditions.map(
      sc => sc.propertyValues[0]
    );
  }

  //find Location conditions and get geoId conditionals from them
  const locationBooleanCondition = include.subConditions.find(
    sc =>
      Array.isArray(sc.subConditions) &&
      sc.subConditions.find(
        subsc => subsc.propertyName === ATTR_PROPERTY_NAMES.LOCATIONS
      )
  );
  if (locationBooleanCondition) {
    locationIds = locationBooleanCondition.subConditions.map(
      sc => sc.propertyValues[0]
    );
  }

  // Find Zip Codes
  let zipCodesCondition = include.subConditions.find(
    sc => sc.propertyName === ATTR_PROPERTY_NAMES.ZIP_CODE
  );
  if (zipCodesCondition) {
    zipCodes = zipCodesCondition.propertyValues;
  }

  //find session attribute conditions and build entities for them
  let sessionCondition;
  if (ignoreSessionDevices) {
    //ignore the device condition above
    sessionCondition = include.subConditions
      .filter((sc, i) => i > 0)
      .find(
        sc =>
          Array.isArray(sc.subConditions) &&
          sc.subConditions.find(
            subsc =>
              subsc.propertyName.startsWith('Device') ||
              subsc.propertyName.startsWith('profile') ||
              subsc.propertyName.startsWith('queryParams') ||
              subsc.propertyName.startsWith('placement')
          )
      );
  } else {
    sessionCondition = include.subConditions.find(
      sc =>
        Array.isArray(sc.subConditions) &&
        sc.subConditions.find(
          subsc =>
            subsc.propertyName.startsWith('Device') ||
            subsc.propertyName.startsWith('profile') ||
            subsc.propertyName.startsWith('queryParams') ||
            subsc.propertyName.startsWith('placement')
        )
    );
  }
  if (sessionCondition) {
    attributesMeet = sessionCondition.join;
    sessionAttributes = sessionCondition.subConditions
      .filter(sc => sc.propertyName)
      .map(sc => formatSessionAttribute(sc));
  }

  // Build exclude filters

  if (exclude && Array.isArray(exclude.subConditions)) {
    // sourceid
    const excludedSourceIdCondition = exclude.subConditions.find(
      sc => sc.propertyName === ATTR_PROPERTY_NAMES.SOURCEID
    );
    if (excludedSourceIdCondition) {
      excludedSourceIdValues = excludedSourceIdCondition.propertyValues;
    }

    // user segments
    const excludedUserSegmentBooleanCondition = exclude.subConditions.find(
      sc =>
        Array.isArray(sc.subConditions) &&
        sc.subConditions.find(
          subsc => subsc.propertyName === ATTR_PROPERTY_NAMES.USER_SEGMENTS
        )
    );
    if (excludedUserSegmentBooleanCondition) {
      excludedAudienceMeet = excludedUserSegmentBooleanCondition.join;
      excludedAudienceIds = excludedUserSegmentBooleanCondition.subConditions.map(
        sc => sc.propertyValues[0]
      );
    }

    // location
    const locationBooleanCondition = exclude.subConditions.find(
      sc =>
        Array.isArray(sc.subConditions) &&
        sc.subConditions.find(
          subsc => subsc.propertyName === ATTR_PROPERTY_NAMES.LOCATIONS
        )
    );
    if (locationBooleanCondition) {
      excludedLocationIds = locationBooleanCondition.subConditions.map(
        sc => sc.propertyValues[0]
      );
    }

    // Zip Code
    let excludedZipCodeCondition = exclude.subConditions.find(
      sc => sc.propertyName === ATTR_PROPERTY_NAMES.ZIP_CODE
    );
    if (excludedZipCodeCondition) {
      excludedZipCodes = excludedZipCodeCondition.propertyValues;
    }

    // session attributes
    const excludeSessionCondition = exclude.subConditions.find(
      sc =>
        Array.isArray(sc.subConditions) &&
        sc.subConditions.find(
          subsc =>
            subsc.propertyName.startsWith('Device') ||
            subsc.propertyName.startsWith('profile') ||
            subsc.propertyName.startsWith('queryParams') ||
            subsc.propertyName.startsWith('placement')
        )
    );
    if (excludeSessionCondition) {
      excludedAttributesMeet = excludeSessionCondition.join;
      excludedSessionAttributes = excludeSessionCondition.subConditions
        .filter(sc => sc.propertyName)
        .map(sc => formatSessionAttribute(sc));
    }
  }

  return {
    deviceTypes,
    sourceIdValues,
    excludedSourceIdValues,
    audienceMeet,
    audienceIds,
    excludedAudienceMeet,
    excludedAudienceIds,
    attributesMeet,
    sessionAttributes,
    excludedAttributesMeet,
    excludedSessionAttributes,
    locationIds,
    excludedLocationIds,
    zipCodes,
    excludedZipCodes
  };
};

export const parseConditionalFilter = filter => {
  const { include = { subConditions: [] } } = filter || {};

  let attributesMeet = include.subConditions[0]?.join || MEETS[0].value;

  // get the nested session attributes from the filter and format for the UI components to consume
  const sessionAttributes = include.subConditions
    .map(sc => {
      return sc.subConditions.map(sc => formatSessionAttribute(sc));
    })
    .flat();

  return {
    attributesMeet,
    sessionAttributes
  };
};

export const buildConditionalFilter = (sessionAttributes, attributesMeet) => {
  const filter = {
    include: filterBooleanCondition('All')
  };

  if (sessionAttributes.length > 0) {
    const sessionCondition = buildSessionAttributeFilter(
      sessionAttributes,
      attributesMeet
    );

    filter.include.subConditions.push(sessionCondition);
  }
  return filter;
};

const isDeviceVersionConditionValid = deviceType => {
  return (
    typeof deviceType.comparisonOperator !== 'undefined' &&
    Array.isArray(deviceType.propertyValues) &&
    deviceType.propertyValues.length > 0 &&
    !!deviceType.propertyValues[0]
  );
};

//turns the FilterBuilder UI into an executable filter
export const buildFilter = ({
  deviceTypes,
  sourceIdValues,
  excludedSourceIdValues,
  audienceIds,
  excludedAudienceIds,
  audienceMeet,
  excludedAudienceMeet,
  sessionAttributes,
  excludedSessionAttributes,
  attributesMeet,
  excludedAttributesMeet,
  locationIds,
  excludedLocationIds,
  zipCodes,
  excludedZipCodes
}) => {
  const filter = {
    include: filterBooleanCondition('All'),
    exclude: filterBooleanCondition('Any')
  };

  if (deviceTypes?.length > 0) {
    const deviceTypeCondition = filterBooleanCondition('Any');
    deviceTypes.forEach(dt => {
      if (dt.value === 'android') {
        //create a boolean condition for android device and device version
        const androidVersionCondition = filterBooleanCondition('All');
        androidVersionCondition.subConditions.push(
          filterCondition('Device.OsName', 'Equal', ['Android'])
        );
        if (isDeviceVersionConditionValid(dt)) {
          androidVersionCondition.subConditions.push(
            filterCondition(
              'Device.OsMajorVersion',
              dt.comparisonOperator,
              dt.propertyValues
            )
          );
        }

        deviceTypeCondition.subConditions.push(androidVersionCondition);
      } else if (dt.value === 'ios') {
        //create a boolean condition for ios device and device version
        const iosVersionCondition = filterBooleanCondition('All');
        iosVersionCondition.subConditions.push(
          filterCondition('Device.OsName', 'Equal', ['iOS'])
        );
        if (isDeviceVersionConditionValid(dt)) {
          iosVersionCondition.subConditions.push(
            filterCondition(
              'Device.OsMajorVersion',
              dt.comparisonOperator,
              dt.propertyValues
            )
          );
        }

        deviceTypeCondition.subConditions.push(iosVersionCondition);
      } else if (dt.value === 'desktop') {
        //not android or ios
        const notAndroidOrIOSCondition = filterBooleanCondition('All');
        notAndroidOrIOSCondition.subConditions.push(
          filterCondition('Device.OsName', 'NotEqual', ['Android'])
        );
        notAndroidOrIOSCondition.subConditions.push(
          filterCondition('Device.OsName', 'NotEqual', ['iOS'])
        );
        deviceTypeCondition.subConditions.push(notAndroidOrIOSCondition);
      }
    });

    filter.include.subConditions.push(deviceTypeCondition);
  }
  if (sourceIdValues?.length > 0) {
    const sourceIdCondition = filterCondition(
      ATTR_PROPERTY_NAMES.SOURCEID,
      'In',
      sourceIdValues
    );

    filter.include.subConditions.push(sourceIdCondition);
  }

  if (excludedSourceIdValues?.length > 0) {
    const excludedSourceIdCondition = filterCondition(
      ATTR_PROPERTY_NAMES.SOURCEID,
      'In',
      excludedSourceIdValues
    );
    filter.exclude = filterBooleanCondition('Any');
    filter.exclude.subConditions.push(excludedSourceIdCondition);
  }

  if (zipCodes?.length > 0) {
    const zipCodesCondition = filterCondition(
      ATTR_PROPERTY_NAMES.ZIP_CODE,
      'Like',
      zipCodes
    );

    filter.include.subConditions.push(zipCodesCondition);
  }

  if (excludedZipCodes?.length > 0) {
    const excludedZipCodesCondition = filterCondition(
      ATTR_PROPERTY_NAMES.ZIP_CODE,
      'Like',
      excludedZipCodes
    );
    filter.exclude = filterBooleanCondition('Any');
    filter.exclude.subConditions.push(excludedZipCodesCondition);
  }

  if (audienceIds?.length > 0) {
    const audienceCondition = filterBooleanCondition(audienceMeet);
    audienceCondition.subConditions = audienceIds.map(aId => {
      return filterCondition(ATTR_PROPERTY_NAMES.USER_SEGMENTS, 'Contains', [
        aId
      ]);
    });
    filter.include.subConditions.push(audienceCondition);
  }

  if (excludedAudienceIds?.length > 0) {
    const audienceCondition = filterBooleanCondition(excludedAudienceMeet);
    audienceCondition.subConditions = excludedAudienceIds.map(aId => {
      return filterCondition(ATTR_PROPERTY_NAMES.USER_SEGMENTS, 'Contains', [
        aId
      ]);
    });
    filter.exclude.subConditions.push(audienceCondition);
  }

  if (locationIds?.length > 0) {
    const locationCondition = filterBooleanCondition('Any');
    locationCondition.subConditions = locationIds.map(id => {
      return filterCondition(ATTR_PROPERTY_NAMES.LOCATIONS, 'Contains', [id]);
    });
    filter.include.subConditions.push(locationCondition);
  }

  if (excludedLocationIds?.length > 0) {
    const locationCondition = filterBooleanCondition('Any');
    locationCondition.subConditions = excludedLocationIds.map(id => {
      return filterCondition(ATTR_PROPERTY_NAMES.LOCATIONS, 'Contains', [id]);
    });
    filter.exclude.subConditions.push(locationCondition);
  }

  if (sessionAttributes?.length > 0) {
    const sessionCondition = buildSessionAttributeFilter(
      sessionAttributes,
      attributesMeet
    );

    filter.include.subConditions.push(sessionCondition);
  }

  if (excludedSessionAttributes?.length > 0) {
    const sessionCondition = buildSessionAttributeFilter(
      excludedSessionAttributes,
      excludedAttributesMeet
    );

    filter.exclude.subConditions.push(sessionCondition);
  }
  return filter;
};
